import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { getImage } from 'gatsby-plugin-image';
import FullWidthImage from '../components/FullWidthImage';
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// Template for individual post (on its own page)
// eslint-disable-next-line
export const ProjectPostTemplate = ({
	title,
	subtitle,
	date,
	order,
	client,
	content,
	contentComponent,
	tags,
	image,
	bannerImage,
	showBannerImage,
	helmet,
	hidden
}) => {
	console.log('featured image:', image)

	const PostContent = contentComponent || Content
	const previewImage = getImage(image) || image
	const heroImage = getImage(bannerImage) || bannerImage


	return (
		<>
			{bannerImage && showBannerImage && <FullWidthImage img={heroImage} />}
			<section className='section'>
				{helmet || ''}
				<div className='container content'>
					<div className='columns'>
						<div className='column is-8 is-offset-2'>
							<h1 className='title is-size-2 has-text-weight-bold is-bold-light'>{title}</h1>
							<p className='is-size-4'>{subtitle}</p>
							{/* <p>Subtitle: {subtitle}</p> */}
							{/* <p>Price: {price}</p> */}
							{/* <p className='mb-0'>
							<b>Date:</b> {date}
						</p> */}

							{/* Everything Else Goes Here */}
							<PostContent content={content} />

							{/* TAGS */}
							{tags && tags.length ? (
								<div style={{ marginTop: `4rem` }}>
									<h4>Tags</h4>
									<ul className='taglist'>
										{tags.map((tag) => (
											<li key={tag + `tag`}>
												<Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
											</li>
										))}
									</ul>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

ProjectPostTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	description: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	helmet: PropTypes.object,
}

const ProjectPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
		<Layout>
			<ProjectPostTemplate
				content={post.html}
				contentComponent={HTMLContent}
				helmet={
					<Helmet titleTemplate='%s | Project'>
						<title>{`${post.frontmatter.title}`}</title>
						<meta name='description' content={`${post.frontmatter.subtitle}`} />
					</Helmet>
				}
				tags={post.frontmatter.tags}
				title={post.frontmatter.title}
				subtitle={post.frontmatter.subtitle}
				date={post.frontmatter.date}
				image={post.frontmatter.image}
				order={post.frontmatter.order}
				client={post.frontmatter.client}
				bannerImage={post.frontmatter.bannerImage}
				showBannerImage={post.frontmatter.showBannerImage}
			/>
		</Layout>
	)
};

ProjectPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ProjectPost;

export const projectQuery = graphql`
	query ProjectPostByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				subtitle
				order
				client
				tags
				hidden
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: CONSTRAINED)
					}
				}
				bannerImage {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				showBannerImage
			}
		}
	}
`
